@import "~@angular/material/theming";
@import "src/app/shared/style/shared.colors";
@import "../node_modules/angular-calendar/css/angular-calendar.css";

@include mat-core();

@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500&display=swap");

/* ======== Angular material custom themes ======== */

$md-m: (
  50 : #e0f2f3,
  100 : #b3dee2,
  200 : #80c8cf,
  300 : #4db2bc,
  400 : #26a2ad,
  500 : #00919f,
  600 : #008997,
  700 : #007e8d,
  800 : #007483,
  900 : #006272,
  A100 : #a1efff,
  A200 : #6ee6ff,
  A400 : #3bddff,
  A700 : #21d9ff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$custom-primary: mat-palette($md-m);
$custom-accent: mat-palette($mat-pink, 100, 500, A100);

$custom-theme: mat-define-light-theme((
  color: (
    primary: $custom-primary,
    accent: $custom-accent,
  )
));

@include angular-material-theme($custom-theme);

@import '~bootstrap/dist/css/bootstrap.min.css';

@import '~@angular/material/theming';

$custom-typography: mat-typography-config($font-family: '-apple-system, BlinkMacSystemFont, sans-serif;');
@include mat-core($custom-typography);

html, body {
  height: 100%;
}

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif; // "Open Sans", sans-serif;
}

.bg-primary {
  background-color: $color-accent !important;
}
